<template>
    <div>
      <ServerError v-if="ServerError" />
      <vue-element-loading
        :active="appLoading"
        spinner="bar-fade-scale"
        color="black"
        size="128"
        is-full-screen
      />
      <v-snackbar v-model="showsnackbar" color="black" right>
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showsnackbar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap v-if="story" pa-5>
        <v-flex xs6>
          <v-layout wrap>
            <v-flex xs12 text-left>
              <span style="color: #000; font-family: poppinsbold; font-size: 25px"
                >Middle Banner</span
              >
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6 text-right py-5>
          <v-dialog
            :retain-focus="false"
            v-model="dialog"
            persistent
            max-width="800px"
            :key="dialog"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark color="green" v-bind="attrs" v-on="on"> UPDATE </v-btn>
            </template>
            <v-card>
              <v-form>
                <v-card-title>
                  <span class="headline">Updates</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <ImageComp
                          :singleImage="story.middleBanner"
                          @stepper="winStepper"
                          :heading="'Upload Image'"
                          :componentType="'bannerImage'"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false">
                    Close
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="uploadBannerImage()">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-flex>
  
        <v-flex xs12 sm6 md4>
          <v-img :src="mediaUURL + story.middleBanner" contain height="200px"></v-img>
        </v-flex>
      </v-layout>
    </div>
  </template>
    <script>
  import axios from "axios";
  import ImageComp from "@/components/Common/singleImages";
  export default {
    components: {
      ImageComp,
    },
    data() {
      return {
        showsnackbar: false,
        ServerError: false,
        msg: null,
        appLoading: false,
        dialog: false,
        itemid: "",
        bannerImage: "",
        story: {},
        itemediting: [],
        editdialog: false,
        editingitem: {},
        officials: [],
        rules: {
          required: (value) => !!value || "Required.",
          min: (v) => v.length >= 8 || "Min 8 characters",
        },
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      winStepper(window_data) {
        if (window_data.type == "bannerImage") {
          this.bannerImage = window_data.selectedFiles;
        }
      },
      getData() {
        this.appLoading = true;
        axios({
          url: "/view/website/content",
          method: "GET",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.story = response.data.websiteDetails;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    //   update() {
    //     var data = {};
    //     data["description"] = this.story.description;
    //     axios({
    //       url: "/edit/ourStory",
    //       method: "POST",
    //       data: data,
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //     })
    //       .then((response) => {
    //         this.appLoading = false;
    //         if (response.data.status) {
    //           if (this.bannerImage) {
    //             this.uploadBannerImage();
    //           }
    //           this.msg = "Edited Sucessfully";
    //           this.showsnackbar = true;
    //           this.dialog = false;
    //           this.getData();
    //         } else {
    //           this.msg = response.data.msg;
    //           this.showsnackbar = true;
    //         }
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   },
      uploadBannerImage() {
        let formData = new FormData();
        formData.append("photo", this.bannerImage);
        axios({
          method: "POST",
          url: "/middleBanner/addOrEdit",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
              this.getData();
              this.showsnackbar = true;
              this.msg = "Uploaded Successfully";
              this.dialog = false;
            } else {
              this.msg = "Can't Update";
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
    <style scoped>
  .item_title {
    font-weight: bold;
    font-family: poppinssemibold;
  }
  </style>
      